import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useUserMetadata } from 'admin-portal-shared-services';

import { useEnvContext } from 'utils/EnvProvider';
import { getAdminPortalSharedBff } from 'services/getAdminPortalSharedBff';
import {
  fetchSupportedCountriesStart,
  fetchSupportedCountriesSuccess,
  fetchSupportedCountriesError,
  setSupportedCountries,
} from 'store/slices/supportedCountries.slice';

const useUserMetadataService = () => {
  const { data } = useUserMetadata();
  const dispatch = useDispatch();
  const { api } = getAdminPortalSharedBff();
  const { REACT_REQUEST_TRACE_ID_ADMIN_PORTAL_SHARED_BFF } = useEnvContext();

  const getSupportedCountries = useCallback(async () => {
    dispatch(fetchSupportedCountriesStart());
    try {
      const supportedCountries = data.supportedCountries || [];

      dispatch(setSupportedCountries(supportedCountries));
      dispatch(fetchSupportedCountriesSuccess(supportedCountries));
    } catch (error) {
      dispatch(fetchSupportedCountriesError(error));
    }
  }, [api, dispatch, REACT_REQUEST_TRACE_ID_ADMIN_PORTAL_SHARED_BFF]);

  return { getSupportedCountries };
};

export default useUserMetadataService;
